<template>
    <v-card :disabled="disabled">
        <v-list-item :dense="dense" @click.stop="toggle" :disabled="disabled">
            <v-list-item-icon class="my-auto">
                <v-icon v-if="v" :class="trueClass">{{ icon || trueIcon }}</v-icon>
                <v-icon v-else :class="falseClass">{{ icon || falseIcon }}</v-icon>
            </v-list-item-icon>
            <slot name="content">
                <v-list-item-content>
                    <v-list-item-title v-if="title != null" class="text-wrap">{{ title }}</v-list-item-title>
                    <v-list-item-subtitle v-if="subtitle != null" class="text-wrap">{{ subtitle }}</v-list-item-subtitle>
                </v-list-item-content>
            </slot>
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    name: 'BT-List-Item-Check',
    data: function() {
        return {
            v: false,
            actualValue: null,
        }
    },
    props: {
        value: null,
        dense: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        falseClass: {
            type: String,
            default: 'error--text'
        },
        falseIcon: {
            type: String,
            default: 'mdi-close'
        },
        falseValue: {
            type: [String, Boolean],
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        subtitle: null,
        title: null,
        trueClass: {
            type: String,
            default: 'success--text'
        },
        trueIcon: {
            type: String,
            default: 'mdi-check'
        },
        trueValue: {
            type: [String, Boolean],
            default: true
        },
    },
    watch: {
        value: function(val) {
            if (this.actualValue !== val) {
                this.v = val == this.trueValue;
            }
            this.actualValue = val;
        }
    },
    created() {
        this.actualValue = this.value;
        this.v = this.actualValue == this.trueValue;
    },
    methods: {
        toggle() {
            this.v = !this.v;
            this.actualValue = this.v ? this.trueValue : this.falseValue;
            
            this.$emit('input', this.actualValue);
            this.$emit('change', this.actualValue);

            if (this.v) {
                this.$emit('true', this.actualValue);
            }
            else {
                this.$emit('false');
            }
        },
        // update(v) {
        //     this.$emit('input', v);
        //     this.$emit('change', v);

        //     if (v) {
        //         this.$emit('true', v);
        //     }
        // }
    }
}
</script>